<template>
    <PageElementWrapper no-padding>
        <div
            class="lg:px-40 relative mb-40">
            <div class="relative z-10">
                <h1
                    class="text-5xl pt-20">
                    {{ title }}
                </h1>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3">
                <div>
                    <div class="hidden md:block absolute top-0 left-0">
                        <svg
                            width="354"
                            height="275"
                            viewBox="0 0 354 275"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <rect
                                x="-3"
                                y="48.7307"
                                width="327"
                                height="232"
                                rx="24"
                                transform="rotate(-9.10231 -3 48.7307)"
                                fill="#FED42F" />
                        </svg>
                    </div>
                </div>
                <p class="pt-8 z-10 col-span-2">
                    {{ text }} <NuxtLink
                        v-if="link && linkText"
                        class="underline underline-offset-2"
                        :to="localePath(link)">
                        {{ linkText }}
                    </NuxtLink>
                </p>
            </div>
        </div>
    </PageElementWrapper>
</template>

<script setup>
const props = defineProps({
    component: {
        type: Object,
        required: true
    }
})

const title = computed(() => {
    return props.component.Titel ?? ''
})

const text = computed(() => {
    return props.component.Text ?? ''
})

const link = computed(() => {
    return '/' + props.component.Link ?? ''
})

const linkText = computed(() => {
    return props.component.LinkText ?? ''
})
</script>
